import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

const RedirectToCaseStudy = () => {
  useEffect(() =>
    navigate('/customers/how-clokka-released-marketplace-app-in-3-weeks/')
  );
  return <div />;
};

export default RedirectToCaseStudy;
